import React, { useEffect } from "react";
import { useRive, Layout, Fit, useStateMachineInput, decodeFont } from "@rive-app/react-canvas";

const RiveDoughnut = ({ PercentagePool, MoneyPool, PercentageShared, MoneyShared, Number, autoPlay }) => {
  const { rive, RiveComponent } = useRive({
    src: "/Rive/graphs.riv",
    artboard: "Graph 1 v2",
    stateMachines: "Graph SM",
    layout: new Layout({
      fit: Fit.Contain,
    }),
    autoplay: false, // Managed dynamically
    assetLoader: (asset, bytes) => {
      if (asset.isFont) {
        fetch("/Rive/Inter-594377.ttf")
          .then((res) => res.arrayBuffer())
          .then((buffer) => {
            decodeFont(new Uint8Array(buffer)).then((font) => {
              asset.setFont(font);
              font.unref();
            });
          });
        return true;
      }
      return false;
    },
  });

  const numberInput = useStateMachineInput(rive, "Graph SM", "Number");

  // Handle autoplay dynamically
  useEffect(() => {
    if (rive) {
      if (autoPlay) {
        rive.play();
      } else {
        rive.pause();
      }
    }
  }, [rive, autoPlay]);

  // Update state machine input when `Number` changes
  useEffect(() => {
    if (numberInput) {
      numberInput.value = Number;
    }
  }, [Number, numberInput]);

  // Update text values in the Rive file dynamically
  useEffect(() => {
    if (rive) {
      rive.setTextRunValue("MoneyShared", `${MoneyShared}`);
      rive.setTextRunValue("PercentageShared", `${PercentageShared}%`);
      rive.setTextRunValue("MoneyPool", `${MoneyPool}`);
      rive.setTextRunValue("PercentagePool", `${PercentagePool}%`);
    }
  }, [rive, MoneyShared, PercentageShared, MoneyPool, PercentagePool]);

  return (
    <div className="PieChart__InnerContainer"
    //  style={{ width: "100%", height: "100%" }}
    >
      <RiveComponent />
    </div >
  );
};

export default RiveDoughnut;