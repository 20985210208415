import React, { useEffect, useState } from "react";
import "../CSS/notif.css";

export default function Notif({
  isInInbox,
  classname = "",
  from,
  id,
  type,
  subject,
  invoiceNumber,
  email,
  message,
  dueDate,
  amount,
  dateReceived,
  isread = false,
  animationDelay = 0,
  selectedNotifDefault = false,
  checkedAll,
  setNotifDetailShown,
  setNotifDataForDetail,
  to,
  user_name,
  user_id,
  accepted,
  invoiceTo,
  receipt

}) {
  const [read, setIsRead] = useState(isread);
  const [selectedNotif, setSelectedNotif] = useState(selectedNotifDefault);

  useEffect(() => {
    setSelectedNotif(checkedAll);
  }, [checkedAll]);

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      console.log(id); // AA TEST + GO BACK TO to implement the BACKEND
      setSelectedNotif(true);
    } else {
      setSelectedNotif(false);
    }
  };

  const handleParentClick = (event) => {
    const checkbox = event.currentTarget.querySelector('.checkbox');
    if (checkbox) {
      checkbox.checked = !checkbox.checked;
      handleCheckboxChange({ target: checkbox });
      // console.log(checkbox.id); // AA TEST
    }
  };
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  let dateMonth = "";
  if (dueDate) {
    const [month, year] = dueDate.split("-");
    dateMonth = `${monthNames[parseInt(month, 10) - 1]} ${year}`;
  }

  return (
    isInInbox ? <>

      <div
        className={read ? `${classname} notifCard notifCard--Inbox notifCard--read` : `${classname} notifCard notifCard--Inbox`}
        key={id}
        // onClick={handleParentClick} // AA GO BACK TO not neccassary 
        style={{ animationDuration: `${animationDelay}s` }}
      >

        {type === "Email" ? <>
          <div className="notifCardAll">
            <input
              key={id}
              type="checkbox"
              className="checkbox"
              onClick={(e) => e.stopPropagation()}
              onChange={handleCheckboxChange}
              checked={selectedNotif}
            />

            <div
              onClick={() => {
                setNotifDetailShown(true);
                setNotifDataForDetail({
                  'from': to === 0 || to === "0" ? user_name : to === 1 || to === "1" ? "You" : to,
                  'id': id,
                  'type': type,
                  'subject': subject,
                  'invoiceNumber': invoiceNumber,
                  'email': email,
                  'message': message,
                  'dueDate': dueDate,
                  'amount': amount,
                  'dateReceived': dateReceived,
                  'isread': isread,
                  'accepted': accepted,
                  'to': to === 0 || to === "0" ? "You" : to === 1 || to === "1" ? user_name : to,
                  "user_name": user_name,
                  "user_id": user_id,
                  "receipt": receipt
                });
              }
              } className="Link Link--Inbox">
              <h3 className="FromName"> {to === 0 || to === "0" ? user_name : to === 1 || to === "1" ? "You" : to}</h3>
              <div className="flex flex--Email">
                <h5>{subject}</h5>
                <p>- {`${message.slice(0, 9)}...`}</p>
              </div>
              <h3 className="dateAgo">{dateReceived}</h3>
            </div>
          </div>
        </>
          : type === "Profits" ?
            <>
              <div className="notifCardAll">
                <input
                  type="checkbox"
                  className="checkbox"
                  onClick={(e) => e.stopPropagation()}
                  onChange={handleCheckboxChange}
                  checked={selectedNotif}
                />

                <div
                  onClick={() => {
                    setNotifDetailShown(true);
                    setNotifDataForDetail({
                      'from': "You",
                      'id': id,
                      'type': type,
                      'subject': subject,
                      'invoiceNumber': invoiceNumber,
                      'email': email,
                      'message': message,
                      'dueDate': dueDate,
                      'amount': amount,
                      'dateReceived': dateReceived,
                      'isread': isread,
                      'accepted': accepted,
                      "invoiceTo": invoiceTo,
                      "user_name": user_name,
                      "user_id": user_id,
                      'to': user_name,
                      "receipt": receipt
                    });
                  }
                  } className="Link Link--Inbox">
                  <h3 className="FromName">You</h3>
                  <div className="flex flex--InvoiceParent">
                  <div className="flex flex--Invoice">
                      <p>Profit Distribution of: </p><h5>‎ {dateMonth} ‎</h5>
                    </div>
                    <div className="flex flex--Invoice">
                      <p> To: </p>
                      <h5> {user_name} ‎</h5>
                    </div>
                    <div className="flex flex--Invoice">
                      <p> Amount:  </p>
                      <h5> ${amount}</h5>
                    </div>
                  </div>
                  <h3 className="dateAgo">{dateReceived}</h3>
                </div>
              </div>
            </> : type === "Invoice" ?
            <>
              <div className="notifCardAll">
                <input
                  type="checkbox"
                  className="checkbox"
                  onClick={(e) => e.stopPropagation()}
                  onChange={handleCheckboxChange}
                  checked={selectedNotif}
                />

                <div
                  onClick={() => {
                    setNotifDetailShown(true);
                    setNotifDataForDetail({
                      'from': to === 0 || to === "0" ? user_name : to === 1 || to === "1" ? "You" : to,
                      'id': id,
                      'type': type,
                      'subject': subject,
                      'invoiceNumber': invoiceNumber,
                      'email': email,
                      'message': message,
                      'dueDate': dueDate,
                      'amount': amount,
                      'dateReceived': dateReceived,
                      'isread': isread,
                      'accepted': accepted,
                      "invoiceTo": invoiceTo,
                      "user_name": user_name,
                      "user_id": user_id,
                      'to': to === 0 || to === "0" ? "You" : to === 1 || to === "1" ? user_name : to,
                      "receipt": receipt
                    });
                  }
                  } className="Link Link--Inbox">
                  <h3 className="FromName">{to === 0 || to === "0" ? user_name : to === 1 || to === "1" ? "You" : to}</h3>
                  <div className="flex flex--InvoiceParent">
                    <div className="flex flex--Invoice">
                      <h5>Type: </h5>
                      <p> {receipt}</p>
                    </div>
                    <div className="flex flex--Invoice">
                      <h5>, Amount: </h5>
                      <p>{amount}</p>
                    </div>
                    <div className="flex flex--Invoice">
                      <h5>, Status: </h5>
                      <p>{accepted === 1 ? "Accepted" : accepted === 0 ? "Declined" : "Pending"}</p>
                    </div>
                  </div>
                  <h3 className="dateAgo">{dateReceived}</h3>
                </div>
              </div>
            </>: <h1>Error404 - Cant Load Content</h1>
        }
      </div>
    </> : <>
      <div
        className={read ? `${classname} notifCard notifCard--read` : `${classname} notifCard`}
        key={id}
        onClick={handleParentClick}
        style={{ animationDuration: `${animationDelay}s` }}
      >
        {type === "Email" ? (
          <div className="notifCardAll">
            <div className="notifCard__header">
              <h3>{`${user_name} sent you a new email`}</h3>
              <p className="dateAgo">{dateReceived}</p>
            </div>
            <div className="flex">
              <h5>Subject: </h5>
              <p>{subject}</p>
            </div>
          </div>
        ) : (
          <>
            <div className="notifCard__header">
              <h3>{`${from} sent you a new receipt`}</h3>
              <p className="dateAgo">{dateReceived}</p>
            </div>
            <div className="flex" style={{ gap: '.35rem' }}>
              <h5>Receipt Number: </h5>
              <p>{invoiceNumber}</p>
              <h5>, Due Date: </h5>
              <p>{dueDate}</p>
              {/* <h5>, Amount: </h5>
              <p>{amount}</p> */}
            </div>
          </>
        )}
      </div>
    </>
  );
}
